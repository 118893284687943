var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm._m(0);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-xxl-4 col-xl-4 col-md-12 col-sm-12 mb-3"
  }, [_c('div', {
    staticClass: "new-table-card"
  }, [_c('h3', [_vm._v("Domain Visitors")]), _c('div', {
    staticClass: "table-responsive"
  }, [_c('table', {
    staticClass: "table"
  }, [_c('thead', [_c('tr', [_c('th', [_vm._v("Domain Visitors")]), _c('th', [_vm._v("Visitors")])])]), _c('tbody', [_c('tr', [_c('td', [_c('span', {
    staticClass: "username"
  }, [_vm._v("brokersexchange.com")])]), _c('td', [_c('span', {
    staticClass: "last-login"
  }, [_vm._v("04")])])]), _c('tr', [_c('td', [_c('span', {
    staticClass: "username"
  }, [_vm._v("brokersexchange.com")])]), _c('td', [_c('span', {
    staticClass: "last-login"
  }, [_vm._v("04")])])])])])]), _c('div', {
    staticClass: "table-pagination"
  }, [_c('ul', [_c('li', [_c('a', {
    staticClass: "active-table",
    attrs: {
      "href": "#"
    }
  }, [_vm._v("1")])]), _c('li', [_c('a', {
    attrs: {
      "href": "#"
    }
  }, [_vm._v("2")])]), _c('li', [_c('a', {
    attrs: {
      "href": "#"
    }
  }, [_vm._v("3")])]), _c('li', [_c('a', {
    attrs: {
      "href": "#"
    }
  }, [_vm._v("4")])]), _c('li', [_c('a', {
    staticClass: "next-highlight",
    attrs: {
      "href": "#"
    }
  }, [_vm._v("Next")])])])])])]), _c('div', {
    staticClass: "col-xxl-8 col-xl-8 col-md-12 col-sm-12 mb-3"
  }, [_c('div', {
    staticClass: "new-table-card"
  }, [_c('h3', [_vm._v("Domain Visitors")]), _c('div', {
    staticClass: "table-responsive"
  }, [_c('table', {
    staticClass: "table"
  }, [_c('thead', [_c('tr', [_c('th', [_vm._v("IP")]), _c('th', [_vm._v("Site")]), _c('th', [_vm._v("Location")]), _c('th', [_vm._v("Browser")])])]), _c('tbody', [_c('tr', [_c('td', [_c('span', {
    staticClass: "ip"
  }, [_vm._v("100.1.12.205")])]), _c('td', [_c('span', {
    staticClass: "site"
  }, [_vm._v("primeenroll.com")])]), _c('td', [_c('span', {
    staticClass: "location"
  }, [_vm._v("New Jersy")])]), _c('td', [_c('span', {
    staticClass: "browser"
  }, [_vm._v("Chrome")])])]), _c('tr', [_c('td', [_c('span', {
    staticClass: "ip"
  }, [_vm._v("100.1.12.205")])]), _c('td', [_c('span', {
    staticClass: "site"
  }, [_vm._v("primeenroll.com")])]), _c('td', [_c('span', {
    staticClass: "location"
  }, [_vm._v("New Jersy")])]), _c('td', [_c('span', {
    staticClass: "browser"
  }, [_vm._v("Chrome")])])])])])]), _c('div', {
    staticClass: "table-pagination"
  }, [_c('ul', [_c('li', [_c('a', {
    staticClass: "active-table",
    attrs: {
      "href": "#"
    }
  }, [_vm._v("1")])]), _c('li', [_c('a', {
    attrs: {
      "href": "#"
    }
  }, [_vm._v("2")])]), _c('li', [_c('a', {
    attrs: {
      "href": "#"
    }
  }, [_vm._v("3")])]), _c('li', [_c('a', {
    attrs: {
      "href": "#"
    }
  }, [_vm._v("4")])]), _c('li', [_c('a', {
    staticClass: "next-highlight",
    attrs: {
      "href": "#"
    }
  }, [_vm._v("Next")])])])])])])]);
}]

export { render, staticRenderFns }