var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "overallInfo-blockWrapper"
  }, [_c('div', {
    staticClass: "overallInfo-block"
  }, [_c('div', {
    staticClass: "overallInfo-content"
  }, [_c('h3', [_vm._v("Browser Usage")]), _c('span', {
    staticClass: "total-title"
  }, [_vm._v("Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy.")]), _c('div', {
    staticClass: "valueDenote"
  }, [_c('ul', [_c('li', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      modifiers: {
        "hover": true
      }
    }],
    attrs: {
      "title": "States: 10, No Reps: IA"
    }
  }, [_c('span', {
    staticClass: "value-active"
  }), _vm._v(" Chrome ")]), _c('li', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      modifiers: {
        "hover": true
      }
    }],
    attrs: {
      "title": "States: 10, No Reps: IA"
    }
  }, [_c('span', {
    staticClass: "value-withdrawn"
  }), _vm._v(" Inter Explorer ")]), _c('li', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      modifiers: {
        "hover": true
      }
    }],
    attrs: {
      "title": "States: 10, No Reps: IA"
    }
  }, [_c('span', {
    staticClass: "value-termed"
  }), _vm._v(" Firefox ")]), _c('li', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      modifiers: {
        "hover": true
      }
    }],
    attrs: {
      "title": "States: 10, No Reps: IA"
    }
  }, [_c('span', {
    staticClass: "value-safari"
  }), _vm._v(" Safari ")]), _c('li', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      modifiers: {
        "hover": true
      }
    }],
    attrs: {
      "title": "States: 10, No Reps: IA"
    }
  }, [_c('span', {
    staticClass: "value-other"
  }), _vm._v(" Others ")])])])]), _c('apexchart', {
    staticClass: "info-donut",
    attrs: {
      "height": "80%",
      "type": "donut",
      "options": _vm.options,
      "series": _vm.series
    }
  })], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }