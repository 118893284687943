<template>
  <div class="row">
    <div class="col-xxl-8 col-xl-8 col-md-12 col-sm-12 mb-3">
      <div class="analytics-report">
        <div class="chartDate-filter text-end">
          <div class="b-overviewInfo-total">
            <h2>Weekly Report</h2>
            <span>7 December to 14 December</span>
          </div>
          <div class="b-overviewInfo-denote">
            <ul class="header-data">
              <li>$2,582</li>
              <li>$2,582</li>
            </ul>
            <ul>
              <li><span class="value-direct"></span>Direct</li>
              <li><span class="value-downline"></span>Downline</li>
            </ul>
          </div>
          <form>
            <select>
              <option>Last 7 Days</option>
              <option>Last Month</option>
              <option>This Year</option>
              <option>Last Year</option>
            </select>
          </form>
        </div>

        <div class="spline-chart">
          <apexchart
            type="area"
            height="350"
            :options="chartOptions"
            :series="series"
          ></apexchart>
        </div>
      </div>
    </div>
    <div class="col-xxl-4 col-xl-4 col-md-12 col-sm-12 mb-3 reports-right-div">
      <div class="row">
        <div class="col-12">
          <div class="analytics-blockWrapper">
            <div class="analytics-block">
              <div class="analytics-content">
                <div class="card-image">
                  <div class="circle"></div>
                </div>
                <div class="card-text">
                  <h3>15</h3>
                  <p>Visitor Today</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12">
          <div class="analytics-blockWrapper">
            <div class="analytics-block">
              <div class="analytics-content">
                <div class="card-image">
                  <div class="circle"></div>
                </div>
                <div class="card-text">
                  <h3>15</h3>
                  <p>Visitor Today</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 report-reps-wrap">
          <ReportReps />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ReportReps from "./ReportReps.vue";
export default {
    components: {
        ReportReps,
    },
  data: function () {
    return {
      series: [
        {
          name: "Downline",
          data: [11, 32, 45, 32, 34, 52, 41],
        },
        {
          name: "Direct",
          data: [31, 40, 28, 51, 42, 109, 100],
        },
      ],
      chartOptions: {
        chart: {
          height: 350,
          type: "area",
          toolbar: {
            show: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "smooth",
          width: "2",
        },
        xaxis: {
          type: "category",
          categories: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
        },
        fill: {
          type: "gradient",
          gradient: {
            shadeIntensity: 1,
            opacityFrom: 0.7,
            opacityTo: 0.9,
            stops: [0, 90, 100],
          },
        },
        legend: {
          show: false,
        },
      },
    };
  },
};
</script>