<template>
    <div class="overallInfo-blockWrapper">
        <div class="overallInfo-block">
            <div class="overallInfo-content">
            <h3>Browser Usage</h3>
            <span class="total-title">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy.</span>
            <div class="valueDenote">
                <ul>
                    <li v-b-tooltip.hover title="States: 10, No Reps: IA">
                        <span class="value-active"></span> Chrome
                    </li>
                    <li v-b-tooltip.hover title="States: 10, No Reps: IA">
                        <span class="value-withdrawn"></span> Inter Explorer
                    </li>
                    <li v-b-tooltip.hover title="States: 10, No Reps: IA">
                        <span class="value-termed"></span> Firefox
                    </li>
                    <li v-b-tooltip.hover title="States: 10, No Reps: IA">
                        <span class="value-safari"></span> Safari
                    </li>
                    <li v-b-tooltip.hover title="States: 10, No Reps: IA">
                        <span class="value-other"></span> Others
                    </li>
                </ul>
            </div>
            </div>
            <apexchart
            class="info-donut"
            height="80%"
            type="donut"
            :options="options"
            :series="series"
            ></apexchart>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            options: {
                plotOptions: {
                        pie: {
                            // size: 800,
                            customScale: 1.1,
                            donut: {
                                size: '55%',
                            },                    
                        },
                        
                    },
                    legend:{
                        show: false,
                    },
                    dropShadow:{
                        enable: false
                    },
                    fill: {
                        colors: ['#FFE700', '#2CD9C5', '#826AF9', '#2D99FF', '#F3F5F8']
                    },
                    dataLabels: {
                        dropShadow: {
                            enabled: false,
                        },
                        style: {
                            fontSize:  '8px'
                        }
                    }
            },
            series: [70, 15, 15, 7, 2],
        }
    }
}
</script>