var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm._m(0);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-xxl-3 col-xl-3 col-md-6 col-sm-6 mb-3"
  }, [_c('div', {
    staticClass: "analytics-blockWrapper"
  }, [_c('div', {
    staticClass: "analytics-block"
  }, [_c('div', {
    staticClass: "analytics-content"
  }, [_c('div', {
    staticClass: "card-image"
  }, [_c('div', {
    staticClass: "circle"
  })]), _c('div', {
    staticClass: "card-text"
  }, [_c('h3', [_vm._v("15")]), _c('p', [_vm._v("Visitor Today")])])])])])]), _c('div', {
    staticClass: "col-xxl-3 col-xl-3 col-md-6 col-sm-6 mb-3"
  }, [_c('div', {
    staticClass: "analytics-blockWrapper"
  }, [_c('div', {
    staticClass: "analytics-block"
  }, [_c('div', {
    staticClass: "analytics-content"
  }, [_c('div', {
    staticClass: "card-image"
  }, [_c('div', {
    staticClass: "circle"
  })]), _c('div', {
    staticClass: "card-text"
  }, [_c('h3', [_vm._v("166")]), _c('p', [_vm._v("Visitor Today")])])])])])]), _c('div', {
    staticClass: "col-xxl-3 col-xl-3 col-md-6 col-sm-6 mb-3"
  }, [_c('div', {
    staticClass: "analytics-blockWrapper"
  }, [_c('div', {
    staticClass: "analytics-block"
  }, [_c('div', {
    staticClass: "analytics-content"
  }, [_c('div', {
    staticClass: "card-image"
  }, [_c('div', {
    staticClass: "circle"
  })]), _c('div', {
    staticClass: "card-text"
  }, [_c('h3', [_vm._v("61")]), _c('p', [_vm._v("Visitor Today")])])])])])]), _c('div', {
    staticClass: "col-xxl-3 col-xl-3 col-md-6 col-sm-6 mb-3"
  }, [_c('div', {
    staticClass: "analytics-blockWrapper"
  }, [_c('div', {
    staticClass: "analytics-block"
  }, [_c('div', {
    staticClass: "analytics-content"
  }, [_c('div', {
    staticClass: "card-image"
  }, [_c('div', {
    staticClass: "circle"
  })]), _c('div', {
    staticClass: "card-text"
  }, [_c('h3', [_vm._v("306")]), _c('p', [_vm._v("Visitor Today")])])])])])])]);
}]

export { render, staticRenderFns }