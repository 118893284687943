<template>
    <div class="page-wrapper analytics-wrap">
        <div class="container-fluid">
            <breadcrumb :items="breadcrumbItems" />
            <div class="page-title">
                <h1>Analytics Dashboard</h1>
                <div class="analytic-filter-main">
                    <ul>
                        <li><a class="active" href="#">Day</a></li>
                        <li><a href="#">Week</a></li>
                        <li><a href="#">Month</a></li>
                        <li><a href="#">Year</a></li>
                        <li class="bydate"><a href="#">
                            <div class="enrollmentDate-picker">
                                <date-picker
                                @input="setToDate"
                                input-class="memberSearch-field"
                                placeholder="From"
                                type="date"
                                v-model="filters.enrollment_from"
                                value-type="format"
                                :disabled-date="(date) => { return disabledAfterCurrentDate(date,'start') }"
                                ></date-picker>

                                <date-picker
                                input-class="memberSearch-field"
                                placeholder="To"
                                type="date"
                                v-model="filters.enrollment_to"
                                value-type="format"
                                :disabled="disableDate"
                                :disabled-date="(date) => { return disabledAfterCurrentDate(date,'end') }"
                                ></date-picker>
                            </div>
                        </a></li>
                    </ul>
                </div>
            </div>
            
            <AnalyticsData />
            <AnalyticsReports />
            <DomainVisitors />
        </div>
    </div>
</template>
<script>
import AnalyticsData from "@/views/Analytics/partials/AnalyticsData.vue";
import AnalyticsReports from "@/views/Analytics/partials/AnalyticsReports.vue";
import DomainVisitors from "@/views/Analytics/partials/DomainVisitors.vue";
export default {
    name: 'Analytics',
    components: {
        AnalyticsData,
        AnalyticsReports,
        DomainVisitors,
    },
    data() {
        return {
        filters: this.filterInitalState(),
        disableDate : true,
        breadcrumbItems: [{ title: "Analytics", active: true }],
        };
    },
    methods: {
        setFilters() {
        this.$root.$emit("searchEvent", this.filters);
        },
        filterInitalState() {
            return {
                enrollment_from: "",
            };
        },
        disabledAfterCurrentDate(date,type) {
            const formattedDate = new Date(new Date().setHours(0, 0, 0, 0));
            if(type == 'end') {
                if( date < new Date(new Date(this.filters.enrollment_from).setHours(0, 0, 0, 0))) {
                return true;
                }
            }
            return date > formattedDate.setDate(formattedDate.getDate() - (+(type == 'start')));
        },
        setToDate(date) {
        if(date) {
          this.disableDate= false  
        } else {
          this.filters.enrollment_to = '';
          this.disableDate= true 
        }
      }
    },
}
</script>
<style src="@/assets/css/analytics.css"></style>